.sidebar{
    position: fixed !important;
    overflow-x: hidden !important;
    height: #{$sidebar-height} !important;
    min-width: #{$sidebar-width} !important;
    z-index: 1 !important;
    border-right: #{$sidebar-border-width} solid #{$sidebar-border-color} !important;
    background-color: #{$sidebar-background-color};
}

.sidebar-header{
    padding: #{$sidebar-padding-x} #{$sidebar-padding-y} !important;
    border-bottom: #{$sidebar-border-width} solid #{$sidebar-border-color} !important;
}

.sidebar-body .nav-pills{
    border-radius: 0 !important;
    padding: 0 !important;
    background-color: #{$sidebar-background-color} !important;
    color: #{$black} !important;
}

.sidebar-body .nav-pills .nav-item .nav-link{
    padding: 0.3125rem 1.5rem;
}

.sidebar-body .nav-pills .nav-item .active{
    color: #{$primary} !important;
    font-weight: bolder !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    border-left-width: .15rem !important;
    border-left-style: solid !important;
    border-left-color: #{$primary} !important;
}

.sidebar-footer{
    padding: #{$sidebar-padding-x} #{$sidebar-padding-y} !important;
    border-top: #{$sidebar-border-width} solid #{$sidebar-border-color} !important;
}

.text-cap{
    padding: #{$sidebar-text-cap-padding-top} #{$sidebar-text-cap-padding-right} #{$sidebar-text-cap-padding-bottom} #{$sidebar-text-cap-padding-left} !important;
    font-size: #{$sidebar-text-cap-font-size} !important;
    color: #{$sidebar-text-cap-font-color} !important;
    text-transform: #{$sidebar-text-cap-font-style} !important;
}

.sidebar::-webkit-scrollbar {
    width: #{$sidebar-scrollbar-width};
}

.sidebar-button{
    border: #{$sidebar-button-border-width} !important;
    background-color: #{$link} !important;
    margin-bottom: #{$sidebar-button-margin-bottom};
    padding: #{$sidebar-button-padding};
}

.sidebar-button-icon{
    display: inline-block;
    width: #{$sidebar-button-icon-width};
    height: #{$sidebar-button-icon-height};
    vertical-align: middle;
    background-image: #{$sidebar-button-icon-url};
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.sidebar-button{
    display: none;
}

@media screen and (max-width: #{$sidebar-hidden-property-max-width}) {
    .sidebar{
        display: none;
    }
    .sidebar-button{
        display: block;
    }
}