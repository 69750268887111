.input-group:focus-within .input-group-text,
.form-control:focus ~ .input-group-text,
.input-group-text.input-group-text-validation {
  box-shadow: -2px 0px 15px 0px rgba(187, 187, 187, 0.5) !important;
  background-color: #fff;
  &.is-invalid,
  &.was-validated.is-invalid {
    background-color: $form-feedback-invalid-color !important;
    color: #fff;
    border-color: $form-feedback-invalid-color !important;
  }
  &.is-valid,
  &.was-validated.is-valid {
    background-color: $form-feedback-valid-color !important;
    color: #fff;
    border-color: $form-feedback-valid-color !important;
  }
}
