.icon {
    display: inline-flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    font-size: #{$icon-font-size};
    width: $icon-width;
    height: $icon-height;
    border-radius: $icon-border-radius;
}

.icon-circle{
    border-radius: 50% !important;
}

@each $color, $value in $colors {
    .icon-#{$color} {
        background-color: $value !important;
        @if $color == 'warning' or $color == 'light' or $color == 'link' {
            color: #{$black} !important;
        }
        @else{
            color: #{$white} !important;
        }
    }

    .icon-soft-#{$color} {
        background-color: rgba($value, 0.1) !important;
        color: #{$value} !important;
    }
}

@each $key, $size in $sizes {
    .icon-#{$key} {
        height: $size !important;
        width: $size !important;
        font-size: map-get($font-size, $key) !important;
    }
}