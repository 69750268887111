$card-spacer-y: $spacer * 2;
$card-spacer-x: $spacer * 2;

$card-border-width: 0;
$card-border-radius: .75rem;
$card-color: var(--#{$prefix}gray-600);

// .card{
//     min-width: 20rem !important;
// }

.card .card-link{
    text-decoration: none !important;
}

.card-title{
    color: #{$black} !important;
    font-weight: bolder !important;
}

.card {
    box-shadow: 0 0 25px 0 rgba(230, 230, 230, 0.5) !important;
}

.card-header, .card-footer{
    background-color: #{$white} !important;
}

.card-header{
    border-bottom: 1px solid var(--#{$prefix}gray-400) !important;
}

.card-footer{
    border-top: 1px solid var(--#{$prefix}gray-400) !important;
}