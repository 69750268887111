::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #D7DCE3;
}

::-webkit-scrollbar-thumb:hover {
    background: #a6aaaf;
}