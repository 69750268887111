.thead-light{
    background-color: #{$light-bg} !important;
}

.table tbody > tr:last-child > td, .table tbody > tr:last-child > th{
    border-bottom: 0 !important;
}

.card .table{
    margin-bottom: 0 !important;
}

.thead-light th:first-child {
    border-top-left-radius: #{$card-border-radius} !important;
}

.thead-light th:last-child {
    border-top-right-radius: #{$card-border-radius} !important;
}

$table-cell-padding-y:        .75rem;
$table-cell-padding-x:        1.5rem;
$table-cell-padding-y-sm:     .5rem;
$table-cell-padding-x-sm:     1rem;

$table-striped-bg:            $light-bg;

$table-active-bg:             $light-bg;

$table-hover-bg:              $light-bg;