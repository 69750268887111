[data-bs-toggle="go-to-top"]{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: #{$go-to-top-bottom};
    right: #{$go-to-top-right};
    z-index: #{$go-to-top-z-index};
    background-image: #{$go-to-top-icon};
    background-repeat: no-repeat;
    background-color: #{$go-to-top-bg-color};
    background-position: center center;
    padding: #{$go-to-top-padding};
    border: #{$go-to-top-border-width};
    border-radius: #{$go-to-top-border-radius};
}