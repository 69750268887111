$sizes: (
    "xs" : 1.75rem,
    "sm" : 2.5rem,
    "lg" : 4.25rem
);

$font-size: (
    "xs" : .75rem,
    "sm" : .8125rem,
    "lg" : 1.25rem
);

$icon-font-size: 1rem;
$icon-height: 3.125rem;
$icon-width: $icon-height;
$icon-border-radius: 0.3125rem;