$offcanvas-padding-y: $spacer + .6;
$offcanvas-padding-x: $spacer + 1.2;
$offcanvas-border-width: 0px;
$offcanvas-box-shadow: $box-shadow-lg;
$offcanvas-backdrop-bg: $backdrop-color;
$offcanvas-backdrop-opacity: .25;

.offcanvas-header{
    border-bottom: 1px solid var(--#{$prefix}gray-300) !important;
}

.offcanvas-title{
    font-size: 15px !important;
    font-weight: 600 !important;
}

.offcanvas-body{
    color: var(--#{$prefix}gray-600) !important;
}