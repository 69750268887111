.preview{
    padding: #{$ig-preview-padding-x} #{$ig-preview-padding-y};
}

code[class*="language-"],
pre[class*="language-"] {
    color: #{$ig-code-color};
    background: 0 0;
    font-family: #{$ig-code-font-family};
    font-size: #{$ig-code-font-size};
    text-align: left;
    white-space: pre-wrap;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: #{$ig-code-line-height};
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}
@media print {
    code[class*="language-"],
    pre[class*="language-"] {
        text-shadow: none;
    }
}
:not(pre) > code[class*="language-"],
pre[class*="language-"] {
    background: #{$ig-code-background-color};
}
pre[class*="language-"] {
    padding: 1.5rem 1.75rem;
    overflow: auto;
}
:not(pre) > code[class*="language-"] {
    padding: 0.15em 0.2em 0.05em;
    border-radius: 0.3em;
    white-space: normal;
}
.token.cdata,
.token.comment,
.token.doctype,
.token.prolog {
    color: #{$ig-code-comment-color};
}
.token.punctuation {
    opacity: #{$ig-code-punctuation-opacity};
}
.token.namespace {
    opacity: #{$ig-code-namespace-opacity};
}
.token.boolean,
.token.constant,
.token.number,
.token.property,
.token.symbol,
.token.tag {
    color: #{$ig-code-tag-color};
}
.token.attr-name,
.token.builtin,
.token.char,
.token.inserted,
.token.selector,
.token.string {
    color: #{$ig-code-attr-name-color};
}
.language-css .token.string,
.style .token.string,
.token.entity,
.token.operator,
.token.url,
.token.variable {
    color: #f4b73d;
}
.token.atrule,
.token.attr-value,
.token.keyword {
    color: #{$ig-code-attr-value-color};
}
.token.important,
.token.regex {
    color: #e90;
}
.token.bold,
.token.important {
    font-weight: 700;
}
.token.italic {
    font-style: italic;
}
.token.entity {
    cursor: help;
}
.token.deleted {
    color: red;
}