.avatar{
    position: relative;
    display: inline-block;
    width: #{$avatar-width};
    height: #{$avatar-height};
    border-radius: #{$avatar-border-radius};
}

.avatar:not(img) {
    background-color: #{$white};
}

.avatar-img{
    max-width: #{$avatar-img-width};
    height: #{$avatar-img-height};
    object-fit: cover;
    border-radius: #{$avatar-img-border-radius};
}

.avatar-circle .avatar, .avatar-circle .avatar-img, .avatar-circle .avatar-initials {
    border-radius: #{$avatar-circle-border-radius};
}

.avatar-initials {
    display: flex;
    justify-content: center;
    align-items: center;
    width: #{$avatar-initials-width};
    height: #{$avatar-initials-height};
    font-size:#{$avatar-initials-font-size};
    font-weight: #{$avatar-initials-font-weight};
    pointer-events: none;
    text-transform: uppercase;
    border-radius: #{$avatar-initials-border-radius};
}

.avatar-status{
    position: absolute;
    display: inline-flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    text-align: center;
    vertical-align: baseline;
    border: #{$avatar-status-border-width} solid #{$avatar-status-border-color};
    line-height: #{$avatar-status-line-height};
    border-radius: #{$avatar-status-border-radius};

    bottom: #{$avatar-status-buttom};
    right: #{$avatar-status-right};
    width: #{$avatar-status-width};
    height: #{$avatar-status-height};
    font-size: #{avatar-status-font-size};
}

.avatar-sm-status{
    bottom: #{$avatar-sm-status-buttom};
    right: #{$avatar-sm-status-right};
    width: #{$avatar-sm-status-width};
    height: #{$avatar-sm-status-height};
    font-size: #{avatar-sm-status-font-size};
}

.avatar-lg-status{
    bottom: #{$avatar-lg-status-buttom};
    right: #{$avatar-lg-status-right};
    width: #{$avatar-lg-status-width};
    height: #{$avatar-lg-status-height};
    font-size: #{avatar-lg-status-font-size};
}

@each $color, $value in $colors {
    .avatar-#{$color} .avatar-initials{
        color: #{$white};
        background-color: #{$value};
    }
    .avatar-soft-#{$color} .avatar-initials{
        color: #{$value};
        background-color: rgba($value, 0.1);
    }
    .avatar-status-#{$color} {
        color: #{$white};
        background-color: #{$value};
    }
}

@each $key, $value in $avatars {
    .avatar-#{$key}, .avatar-#{$key} .avatar-initials{
        width: #{$value};
        height: #{$value};
    }
}

@each $key, $value in $avatars-initials-fonts {
    .avatar-#{$key} .avatar-initials{
        font-size: #{$value};
    }
}