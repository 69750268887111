$form-feedback-valid-color: $success;
$form-feedback-invalid-color: $danger;

$form-feedback-icon-valid-color: $form-feedback-valid-color;
$form-feedback-icon-valid: url(""); // url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$form-feedback-icon-valid-color}' viewBox='0 0 16 16'><path d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/></svg>")

$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-invalid: url(""); // url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$form-feedback-icon-invalid-color}' viewBox='0 0 16 16'><path d='M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.553.553 0 0 1-1.1 0L7.1 4.995z'/></svg>")

.was-validated .form-control:valid:focus,
.was-validated .form-control.is-valid:focus,
.was-validated .input-group-text:valid:focus,
.was-validated .input-group-text.is-valid:focus,
.was-validated .custom-select:valid:focus,
.was-validated .custom-select.is-valid:focus {
  border-color: $form-feedback-valid-color !important;
  box-shadow: 0 0 7px 1px rgba($form-feedback-valid-color, 0.5) !important;
}

.was-validated .form-control:invalid:focus,
.was-validated .form-control.is-invalid:focus,
.was-validated .input-group-text:invalid:focus,
.was-validated .input-group-text.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.was-validated .custom-select.is-invalid:focus {
  border-color: $form-feedback-invalid-color !important;
  box-shadow: 0 0 7px 1px rgba($form-feedback-invalid-color, 0.5) !important;
}

.was-validated .form-check-input:valid ~ .form-check-label,
.was-validated .form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label,
.was-validated .form-check-input.is-invalid ~ .form-check-label {
  color: $form-feedback-invalid-color;
}

