$btn-padding-y-sm: 0.5rem;
$btn-padding-x-sm: 1rem;
$btn-font-size-sm: 0.875rem;

$btn-padding-y: 0.6125rem;
$btn-padding-x: $btn-padding-x-sm;
$btn-font-size: 1rem;

$btn-padding-y-lg: 0.75rem;
$btn-padding-x-lg: $btn-padding-x-sm;
$btn-font-size-lg: 1rem;

.btn{
    font-size: 15px !important;
    font-weight: 500 !important;
}

@each $color, $value in $colors {
    .btn-#{$color} {
        @if $color == 'warning' or $color == 'light' or $color == 'link' {
            color: #{$black} !important;
        }
        @else{
            color: #{$white} !important;
        }
    }

    .btn-#{$color}:hover,  .btn-outline-#{$color}:hover{
        box-shadow: 0px 4px 11px rgba($value, .35) !important;
        background-color: mix($value, $black, 85%) !important;
        border-color: mix($value, $black, 90%) !important;
    }

    .btn-outline-#{$color}:hover{
        @if $color == 'warning' or $color == 'light' or $color == 'link' {
            color: #{$black} !important;
        }
        @else{
            color: #{$white} !important;
        }
    }

    .btn-soft-#{$color} {
        background-color: rgba($value, 0.1) !important;
        color: #{$value} !important;
    }

    .btn-soft-#{$color}:hover {
        background-color: #{$value} !important;
        @if $color == 'warning' or $color == 'light' or $color == 'link' {
            color: #{$black} !important;
        }
        @else{
            color: #{$white} !important;
        }
    }

    .btn-ghost-#{$color} {
        background-color: #FFFFFF00 !important;
        color: #{$value} !important;
        border: 0px !important;
    }

    .btn-ghost-#{$color}:hover {
        background-color: rgba($value, 0.1) !important;
        color: #{$value} !important;
    }
}