$navbar-light-color: rgba($black, .55);
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;

$navbar-padding-x: $spacer * 2;
$navbar-nav-link-padding-x: .9rem;
$navbar-brand-margin-end: 5rem;
$navbar-toggler-focus-width: 0px;
$navbar-toggler-border-radius: 0px;

.navbar-toggler{
    border: 0px !important;
}

.navbar-brand-logo{
    height: 35px;
}