[data-bs-toggle="password"]{
    .toggle-password-hide, .toggle-password-show{
        background-repeat: no-repeat;
        background-position: center center;
    }
    .toggle-password-hide{
        background-image: #{$toggle-password-icon-hide};
    }
    .toggle-password-show{
        background-image: #{$toggle-password-icon-show};
    }
}

[data-bs-toggle="password"] .form-control{
    border-right: #{$toggle-password-form-control-border-right} !important;
}

[data-bs-toggle="password"] .input-group-text{
    border-left: #{$toggle-password-input-group-text-border-left} !important;
    padding-right: #{$toggle-password-input-group-text-padding-right} !important;
}