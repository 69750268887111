$dropdown-padding-x: .5rem;
$dropdown-padding-y: .5rem;
$dropdown-spacer: 0;
$dropdown-border-width: 0px;
$dropdown-font-size: 14px;

$dropdown-item-padding-y: $spacer * .4;
$dropdown-item-padding-x: $spacer * 1.5;


.dropdown-menu {
    margin-top: 10px !important;
    border-top: 3px solid #{$primary} !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    box-shadow: #{$box-shadow} !important;
}

.dropdown-item{
    border-radius: .35rem !important;
}

.dropdown-divider{
    margin: 0px 10px !important;
}