$form-check-input-focus-box-shadow: $box-shadow-lg;
$form-switch-width: 2.75em;
$form-check-input-border: 1px solid #E7EAF3 !default;

.form-switch .form-check-input {
    width: 3.8125em;
    height: 1.8125em;
    border-width: 0;
    background-color: #e7eaf3;
    margin-top: -0.25em;
    margin-right: 0.75rem;
}