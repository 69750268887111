$nav-link-padding-y: .5rem;
$nav-link-padding-x: 1.2rem;
$nav-link-font-size: .9rem;
$nav-link-font-weight: 500;
$nav-link-color: var(--#{$prefix}gray-600);
$nav-link-hover-color: $primary;


$nav-pills-link-active-color: $black;
$nav-pills-link-active-bg: $white;

.nav-pills{
    background-color: #F8FAFD !important;
    padding: .25rem !important;
    border-radius: .5rem !important;
}

.nav-segment{
    display: flex !important;
    align-items: center !important;
}

.nav-pills .nav-item .active, .nav-pills .nav-link.active{
    box-shadow: 0 0.1875rem 0.375rem 0 rgb(140 152 164 / 25%) !important;
    border-radius: .5rem !important;
}