$primary : #377DFF;
$danger : #ED4C78;
$success : #00C9A7;
$info : #09A5BE;
$secondary : #71869D;
$warning : #F5CA99;
$light : #F7FAFF;
$dark : #21325b;
$link : #FFFFFF00;

$orange : orange;
$teal : #20c997;
$indigo : #6610f2;

$black: #000000;
$white: #FFFFFF;

$light-bg: #F8FAFD;

$colors: (
  "primary" : $primary,
  "danger" : $danger,
  "success" : $success,
  "info" : $info,
  "secondary" : $secondary,
  "warning" : $warning,
  "light" : $light,
  "dark" : $dark,
  "link" : $link,
  "orange" : $orange,
  "teal" : $teal,
  "indigo" : $indigo
);